import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useParams, Link, Outlet } from "react-router-dom"
import { Api } from "../../AWS/aws"
import Loading from "../../Components/Loading"
import "./AdvanceStudent.css"
import dateFormat from "../../Assets/Js/dateFormat"

const AdvanceStudent = ({ user }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingMyAdvances, setIsLoadingMyAdvances] = useState(true)
    const [isLoadingMyFavoriteAdvances, setIsLoadingMyFavoriteAdvances] = useState(true)
    const [isLoadingAdvances, setIsLoadingAdvances] = useState(true)
    const [myAdvances, setMyAdvances] = useState([])
    const [advances, setAdvances] = useState([])
    const [myFavoriteAdvances, setMyFavoriteAdvances] = useState([])
    const [helpAdvances, setHelpAdvances] = useState([])
    const [advancesOnDate, setAdvancesOnDate] = useState([])
    const [advancesOutOfDate, setAdvancesOutOfDate] = useState([])
    const [advance, setAdvance] = useState({})

    const { id } = useParams()

    const getMyAdvances = (id) => {
        return Api({
            path: `/student/advances/${id}`
        })
    }

    const getMyFavoriteAdvances = (id) => {
        return Api({
            path: `/student/advances/favorite/${id}`
        })
    }

    const getAdvances = (id) => {
        return Api({
            path: `/project/advances/${id}`
        })
    }

    const getAdvancesDescription = (id) => {
        return Api({
            path: `/project/advances/description/${id}`
        })
    }

    useEffect(() => {

        getAdvancesDescription(id).then(res => {
            if (res.code === 0) {
                if (res.body.onDate.length > 0) {
                    let advanceDescription = null
                    res.body.onDate.forEach(element => {
                        if (!advanceDescription) {
                            advanceDescription = element
                        } else {
                            if (advanceDescription.num > element.num) {
                                advanceDescription = element
                            }
                        }
                    });
                    setAdvance(advanceDescription)
                }
                setAdvancesOnDate(res.body.onDate)
                setAdvancesOutOfDate(res.body.outOfDate)
            }
        })

        getMyAdvances(id).then(res => {
            if (res.code === 0) {
                setMyAdvances(res.body)
                setIsLoadingMyAdvances(false)
            } else {
                setIsLoadingMyAdvances(false)
            }
        })

        getAdvances(id).then(res => {
            console.log(res);
            if (res.code === 0) {
                setAdvances(res.body)
                setIsLoadingAdvances(false)
            } else {
                setIsLoadingAdvances(false)
            }
        })

        getMyFavoriteAdvances(id).then(res => {
            console.log(res);
            if (res.code === 0) {
                setMyFavoriteAdvances(res.body)
                setIsLoadingMyFavoriteAdvances(false)
            } else {
                setIsLoadingMyFavoriteAdvances(false)
            }
        })

    }, [])

    useEffect(() => {
        if (isLoading) {
            if (!isLoadingAdvances && !isLoadingMyAdvances && !isLoadingMyFavoriteAdvances) {
                let arradvances = advances
                let arrmyFavoriteAdvances = myFavoriteAdvances
                console.log(arrmyFavoriteAdvances);
                arradvances = arradvances.filter(advance => {
                    let arr = arrmyFavoriteAdvances.filter(data => data.id === advance.id)
                    if (arr.length === 1) {
                        return false
                    } else {
                        return true
                    }
                })
                let helpAdvances = arradvances.filter(advance => advance.help);
                let otherAdvances = arradvances.filter(advance => !advance.help);

                setAdvances(otherAdvances)
                setHelpAdvances(helpAdvances)
                setIsLoading(false)

            }
        }
    })

    return (
        <div className="AdvanceStudent">
            <div className="container-info">
                <h1 className="title">Mis avances {advancesOnDate.length > 0 ? <Button as={Link} variant="secondary" to={"submit?idAdvance=" + advance.id}>Agregar avance</Button> : <></>}</h1>
                {isLoadingMyAdvances ?
                    <Loading disabledAnimation />
                    :
                    myAdvances.length === 0 ?
                        <p>No haz subido ningun avance</p>
                        :
                        <Row className="row-center">
                            {myAdvances.map((data, index) => {
                                return (
                                    <Col key={index} className="col-advance">
                                        <Link to={data.id} style={{ color: "rgb(var(--color-7))" }} className={"text-decoration-none"}>
                                        {
                                                                data.portada?
                                                                <img style={{height:"150px"}} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + data.portada} alt="archivo" />
                                                                :
                                                                <></>
                                                            }
                                            <p>Avance {data.advance.num}</p>
                                            <p>{dateFormat("date", data.date)}</p>
                                        </Link>
                                    </Col>
                                )
                            })}
                        </Row>
                }
            </div>
            <div className="container-info">
                <h1 className="title">Avances favoritos</h1>
                {isLoadingMyFavoriteAdvances ?
                    <Loading disabledAnimation />
                    :
                    myFavoriteAdvances.length === 0 ?
                        <p>No tienes avances favoritos</p>
                        :
                        <Row className="row-center">
                            {myFavoriteAdvances.map((data, index) => {
                                return (
                                    <Col key={index} className="col-advance">
                                        <Link to={data.id + "?fav=true&addFav=true"} style={{ color: "rgb(var(--color-7))" }} className={"text-decoration-none"}>
                                        {
                                                                data.portada?
                                                                <img style={{height:"150px"}} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + data.portada} alt="archivo" />
                                                                :
                                                                <></>
                                                            }
                                            <p>{data.group.name}</p>
                                            <p>Avance {data.advance.num}</p>
                                            <p>{dateFormat("date", data.date)}</p>
                                        </Link>
                                    </Col>
                                )
                            })}
                        </Row>
                }
            </div>
            {/* <div className="container-info">
                <h1 className="title">Avances que necesitan ayuda</h1>
                {isLoadingAdvances || isLoading ?
                    <Loading disabledAnimation />
                    :
                    helpAdvances.length === 0 ?
                        <p>Ningun avance necesita ayuda</p>
                        :
                        <Row className="row-center">
                            {helpAdvances.map((data, index) => {
                                return (
                                    <Col key={index} className="col-advance">
                                        <Link to={data.id} style={{ color: "rgb(var(--color-7))" }} className={"text-decoration-none"}>
                                            <p>{data.group.name}</p>
                                            <p>Avance {data.advance.num}</p>
                                            <p>{dateFormat("date", data.date)}</p>
                                        </Link>
                                    </Col>
                                )
                            })}
                        </Row>
                }
            </div> */}
            <div className="container-info">
                <h1 className="title">Otros avances</h1>
                {isLoadingAdvances || isLoading ?
                    <Loading disabledAnimation />
                    :
                    advances.length === 0 ?
                        <p>No hay existen mas avances</p>
                        :
                        <Row className="row-center">
                            {advances.map((data, index) => {
                                return (
                                    <Col key={index} className="col-advance">
                                        <Link to={data.id + "?addFav=true"} style={{ color: "rgb(var(--color-7))" }} className={"text-decoration-none"}>
                                        {
                                                                data.portada?
                                                                <img style={{height:"150px"}} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + data.portada} alt="archivo" />
                                                                :
                                                                <></>
                                                            }
                                            <div className="group-name">
                                                <p>{data.group.name}</p>
                                            </div>
                                            <p>Avance {data.advance.num}</p>
                                            <p>{dateFormat("date", data.date)}</p>
                                        </Link>
                                    </Col>
                                )
                            })}
                        </Row>
                }
            </div>
            <Outlet />
        </div>
    )
}

export default AdvanceStudent