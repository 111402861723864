import React, { Component } from "react"
import { Api } from "../../AWS/aws"
import { Row, Col, Form } from "react-bootstrap"
import Loading from "../../Components/Loading"
import "./TeamTeacher.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSave } from "@fortawesome/free-regular-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { useNavigate, useParams } from "react-router-dom"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import Swal from 'sweetalert2'

function withParams(Component) {
    return props => <Component {...props} params={useParams()} navigate={useNavigate()} />;
}
class TeamTeacher extends Component {

    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            students: [],
            isLoading: true,
            isLoadingCourse: true,
            course: [],
            isEditGroups: false
        };
    }
    getGroups = (id) => {
        return Api({
            path: `/teacher/groups/${id}`
        })
    }

    postGroupName = (body) => {
        return Api({
            path: `/student/group`,
            body: body,
            method: 'POST'
        })
    }

    postGroups = (body) => {
        return Api({
            path: `/teacher/groups`,
            body: body,
            method: 'POST'
        })
    }

    getStudents = (ipProject) => {
        return Api({
            path: `/student/course/${ipProject}`
        })
    }

    onDragEnd = (result) => {
        let source = result.source
        let destination = result.destination

        let newGroups = this.state.groups
        let newStudents = this.state.students

        if (!destination) {
            return
        }
        if (destination.index === source.index && destination.droppableId === source.droppableId) {
            return
        }

        if (source.droppableId === "droppable student" && destination.droppableId !== source.droppableId) {

            let arr = destination.droppableId.split(" ")
            let i = parseInt(arr[2], 10);

            let aux = newStudents[source.index]
            newStudents.splice(source.index, 1)
            newGroups[i].students.splice(destination.index, 0, aux)

        } if (source.droppableId === "droppable student" && destination.droppableId === source.droppableId) {

            let aux = newStudents[source.index]
            newStudents.splice(source.index, 1)
            newStudents.splice(destination.index, 0, aux)


        } if (destination.droppableId === "droppable student" && destination.droppableId !== source.droppableId) {

            let arr = source.droppableId.split(" ")
            let i = parseInt(arr[2], 10);
            let j = source.index

            let aux = newGroups[i].students[j]
            newGroups[i].students.splice(j, 1)
            if (!newStudents) {
                newStudents = []
            }
            newStudents.splice(destination.index, 0, aux)

        } if (destination.droppableId !== "droppable student" && source.droppableId !== "droppable student" && destination.droppableId !== source.droppableId) {

            let arr = destination.droppableId.split(" ")
            let iDestination = parseInt(arr[2], 10);

            arr = source.droppableId.split(" ")
            let iSource = parseInt(arr[2], 10);
            let jSource = source.index

            let aux = newGroups[iSource].students[jSource]
            newGroups[iSource].students.splice(jSource, 1)
            if (!newGroups[iDestination].students) {
                newGroups[iDestination].students = []
            }
            newGroups[iDestination].students.splice(destination.index, 0, aux)

        } if (destination.droppableId !== "droppable student" && source.droppableId !== "droppable student" && destination.droppableId === source.droppableId) {

            let arr = destination.droppableId.split(" ")
            let iDestination = parseInt(arr[2], 10);

            let aux = newGroups[iDestination].students[source.index]
            newGroups[iDestination].students.splice(source.index, 1)
            newGroups[iDestination].students.splice(destination.index, 0, aux)

        }

        this.setState({ newGroups: newGroups, newStudents: newStudents })

    }

    componentDidMount() {
        let id = this.props.params.id
        this.getGroups(id).then((res) => {
            if (res.code === 0) {
                let arr = res.body.groups.sort(function (b, a) {
                    if (a.name > b.name) {
                        return -1;
                    }
                    if (b.name > a.name) {
                        return 1;
                    }
                    return 0;

                });
                this.setState({ groups: arr, students: res.body.students, isLoading: false })
            } else {
                this.setState({ isLoading: false })
            }
        })

        this.getStudents(id).then(res => {
            if (res.code === 0) {
                this.setState({ isLoadingCourse: false, course: res.body.course })
            } else {

                this.setState({ isLoadingCourse: false })
            }
        })
    }

    render() {
        let groups = this.state.groups
        let students = this.state.students
        let isLoading = this.state.isLoading
        return (
            <div className="TeamTeacher">
                <div className="container-info">
                    <h1 className="title">Curso</h1>
                    {this.state.isLoadingCourse ?
                        <Loading disabledAnimation />
                        :
                        <>
                            {
                                this.state.course.length === 0 ?
                                    <div className="container-info">
                                        <p>Ningun estudiante en el curso</p>
                                    </div>
                                    :
                                    <Row className="row-center">
                                        {this.state.course.map((data, index) => {
                                            let arr = data.name.split(" ")
                                            let name = arr[0] + " " + arr[arr.length - 2] + " " + arr[arr.length - 1][0] + "."
                                            return (
                                                <Col key={"col " + index} className="col-group">
                                                    <div className="text-center" onClick={() => { this.props.navigate("/profile/" + data.id) }}>
                                                        {data.urlImg ? <img width={50} height={50} style={{ objectFit: "cover", borderRadius: "50%" }} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + data.urlImg} alt="foto perfil" /> : <FontAwesomeIcon icon={faUser} style={{ fontSize: "40px" }} />}
                                                        <div className="col-group-student text-center">
                                                            <p>{name}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                        }
                                    </Row>
                            }
                        </>
                    }
                </div>

                <div style={{ position: "relative" }}>
                    {
                        this.state.isEditGroups ?
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Row>
                                    <Col className="p-3">
                                        {this.state.groups.map((group, index) => (
                                            <div className="container-info" key={index}>
                                                <h1 className="title">{group.name}</h1>
                                                <Droppable droppableId={"droppable group " + index}>
                                                    {(droppableProvided) => <ul {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                                                        {group.students.map((data, index) => {
                                                            return (
                                                                <Draggable key={data.id} draggableId={data.id} index={index}>
                                                                    {(DraggableProvided) => <li {...DraggableProvided.dragHandleProps} ref={DraggableProvided.innerRef} {...DraggableProvided.draggableProps}>
                                                                        {data.name}
                                                                    </li>}
                                                                </Draggable>

                                                            )
                                                        })}
                                                        {droppableProvided.placeholder}
                                                    </ul>}
                                                </Droppable>
                                            </div>
                                        ))}

                                    </Col>
                                    <Col className="p-3">
                                        <div className="container-info">
                                            <h1 className="title">Estudiantes sin grupo</h1>
                                            <Droppable droppableId={"droppable student"}>
                                                {(droppableProvided) => <ul {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                                                    {this.state.students.map((data, index) => (
                                                        <Draggable key={data.id} draggableId={data.id} index={index}>
                                                            {(DraggableProvided) => <li {...DraggableProvided.dragHandleProps} ref={DraggableProvided.innerRef} {...DraggableProvided.draggableProps}>
                                                                {data.name}
                                                            </li>}
                                                        </Draggable>
                                                    ))}
                                                    {droppableProvided.placeholder}
                                                </ul>}
                                            </Droppable>
                                        </div>
                                    </Col>
                                </Row>
                            </DragDropContext>
                            :
                            <Row>
                                <Col className="groups-info">
                                    {isLoading ?
                                        <div className="container-info">
                                            <Loading disabledAnimation />
                                        </div>
                                        :
                                        <div>
                                            {
                                                groups.length === 0 ?
                                                    <div className="container-info">
                                                        <p>No existen grupos creados</p>
                                                    </div>
                                                    :
                                                    groups.map((group, index) => {
                                                        return (
                                                            <div className="container-info" style={(groups.length-1 == index)?{marginBottom:0}:{}} key={index}>

                                                                <div style={{ position: "relative" }}>
                                                                    {group.isEditName ?
                                                                        <h1 className="title">

                                                                            {
                                                                                group.isSaving ?
                                                                                    <Loading disabledAnimation />
                                                                                    :
                                                                                    <Form.Control className="changeName" value={group.newNameGroup} onChange={(e) => {
                                                                                        let aux = groups
                                                                                        aux[index].newNameGroup = e.target.value
                                                                                        this.setState({ groups: aux })
                                                                                    }} />
                                                                            }


                                                                        </h1>
                                                                        :
                                                                        <h1 className="title">{group.name}</h1>
                                                                    }
                                                                    <div className="edit">
                                                                        {group.isEditName ?
                                                                            <FontAwesomeIcon
                                                                                icon={faSave}
                                                                                onClick={() => {
                                                                                    let aux = groups
                                                                                    if (!aux[index].isSaving) {
                                                                                        aux[index].isSaving = true
                                                                                        this.setState({ groups: aux })
                                                                                        let body = {
                                                                                            idProyect: this.props.params.id,
                                                                                            newName: aux[index].newNameGroup,
                                                                                            idGroup: aux[index].id
                                                                                        }
                                                                                        this.postGroupName(body).then(res => {
                                                                                            if (res.code === 0) {
                                                                                                Swal.fire({
                                                                                                    icon: 'success',
                                                                                                    text: 'Cambio de nombre exitoso!',
                                                                                                    confirmButtonColor: "#ffc107"
                                                                                                })
                                                                                                aux[index].isEditName = false
                                                                                                aux[index].isSaving = false
                                                                                                aux[index].name = aux[index].newNameGroup
                                                                                                this.setState({ groups: aux })
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                }}
                                                                            />
                                                                            :
                                                                            <FontAwesomeIcon
                                                                                icon={faEdit}
                                                                                onClick={() => {
                                                                                    let aux = groups
                                                                                    aux[index].isEditName = true
                                                                                    aux[index].newNameGroup = aux[index].name
                                                                                    this.setState({ groups: aux })
                                                                                }}
                                                                            />
                                                                        }
                                                                    </div>

                                                                </div>


                                                                <Row>
                                                                    {group.students.map((data, index) => {
                                                                        let arr = data.name.split(" ")
                                                                        let name = arr[0] + " " + arr[arr.length - 2] + " " + arr[arr.length - 1][0] + "."
                                                                        return (
                                                                            <Col key={"col " + index} className="col-group">
                                                                                <div className="text-center">
                                                                                    {data.urlImg ? <img width={50} height={50} style={{ objectFit: "cover", borderRadius: "50%" }} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + data.urlImg} alt="foto perfil" /> : <FontAwesomeIcon icon={faUser} style={{ fontSize: "40px" }} />}
                                                                                    <div className="col-group-student text-center">
                                                                                        <p>{name}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        )
                                                                    })
                                                                    }
                                                                </Row>
                                                            </div>
                                                        )
                                                    })
                                            }
                                        </div>
                                    }
                                </Col>
                                <Col className="list-Student">
                                    {isLoading ?
                                        <div className="container-info">
                                            <Loading disabledAnimation />
                                        </div>
                                        :
                                        <div>
                                            {
                                                students.length === 0 ?
                                                    <div className="container-info">
                                                        <p>Ningun estudiante sin grupo</p>
                                                    </div>
                                                    :
                                                    <div className="container-info">
                                                        <h1 className="title">Estudiantes sin grupo</h1>
                                                        <Row>
                                                            {students.map((data, index) => {
                                                                let arr = data.name.split(" ")
                                                                let name = arr[0] + " " + arr[arr.length - 2] + " " + arr[arr.length - 1][0] + "."
                                                                return (
                                                                    <Col key={"col " + index} className="col-group">
                                                                        <div className="text-center">
                                                                            {data.urlImg ? <img width={50} height={50} style={{ objectFit: "cover", borderRadius: "50%" }} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + data.urlImg} alt="foto perfil" /> : <FontAwesomeIcon icon={faUser} style={{ fontSize: "40px" }} />}
                                                                            <div className="col-group-student text-center">
                                                                                <p>{name}</p>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                )
                                                            })
                                                            }
                                                        </Row>
                                                    </div>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                    }

                    <div className="edit-groups">
                        {this.state.isEditGroups ?
                            <>
                                <FontAwesomeIcon
                                    icon={faSave}
                                    className="px-1"
                                    onClick={() => {
                                        this.setState({ isEditGroups: false, isLoading: true })
                                        let body = {
                                            idProject: this.props.params.id,
                                            groups: this.state.groups,
                                            students: this.state.students
                                        }
                                        this.postGroups(body).then(res => {
                                            Swal.fire({
                                                icon: 'success',
                                                text: 'Actualizacion de grupos guardado exitosamente!',
                                                confirmButtonColor: "#ffc107"
                                            }).then(() => {
                                                window.location = `/project/${this.props.params.id}/team`
                                            })

                                        })
                                    }}
                                />
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    className="px-1"
                                    onClick={() => {
                                        window.location = `/project/${this.props.params.id}/team`
                                    }}
                                />
                            </>
                            :
                            <FontAwesomeIcon
                                icon={faEdit}
                                onClick={() => {

                                    this.setState({ isEditGroups: true })
                                }}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }

}
export default withParams(TeamTeacher);