import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { Row, Col, Button, Modal, Form, FormControl } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { Api } from "../../AWS/aws"
import Loading from "../../Components/Loading"
import listExtension from "../../Assets/Js/listExtension"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from "@fortawesome/free-regular-svg-icons";
import Calendar from 'react-calendar';
import Swal from 'sweetalert2'
import "./BriefStudent.css"
import 'react-calendar/dist/Calendar.css';

const BriefStudent = ({ user }) => {

    const [project, setProject] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const [linkVideoDescription, setLinkVideoDescription] = useState([])
    const [linkVideoStructure, setLinkVideoStructure] = useState([])
    const [linkVideoExample, setLinkVideoExample] = useState([])
    const [linkVideoPattern, setLinkVideoPattern] = useState([])
    const [linkVideoCourseContents, setLinkVideoCourseContents] = useState([])

    const [notes, setNotes] = useState([])
    const [viewNotes, setViewNotes] = useState([])
    const [isLoadingNotes, setIsLoadingNotes] = useState(true)

    const [value, setValue] = useState(new Date());
    const [show, setShow] = useState(false);

    const [newDate, setNewDate] = useState("");
    const [newName, setNewName] = useState("");
    const [newDescription, setNewDescription] = useState("");
    const [isLoadingNote, setIsLoadingNote] = useState(false);

    const getProjectDescription = (id) => {
        return Api({
            path: `/project/${id}`
        })
    }

    const postNotes = (body) => {
        return Api({
            path: `/project/student/notes`,
            body: body,
            method: "POST"
        })
    }

    const getNotes = (id) => {
        return Api({
            path: `/project/student/notes/${id}`
        })
    }

    const onChange = (data) => {
        let newViewNotes = []
        notes.forEach(element => {
            let date = new Date(element.date)
            if (data.toISOString().split("T")[0] === date.toISOString().split("T")[0]) {
                newViewNotes.push(element)
            }
        });
        setViewNotes(newViewNotes)
        setValue(data)
    }

    // const getStringDate = (date) => {
    //     let stringDate = ""

    //     let meses = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"]

    //     let arr = date.split("T")
    //     arr = arr[0].split("-")
    //     let año = arr[0]
    //     let mes = arr[1]
    //     let dia = arr[2]

    //     stringDate = parseInt(dia) + " de " + meses[mes - 1] + " de " + año

    //     return stringDate
    // }

    const { id } = useParams()

    useEffect(() => {
        getNotes(id).then((res) => {
            if (res.code === 0) {
                let newViewNotes = []
                res.body.forEach(element => {
                    let date = new Date(element.date)
                    if (value.toISOString().split("T")[0] === date.toISOString().split("T")[0]) {
                        newViewNotes.push(element)
                    }
                });
                setIsLoadingNotes(false)
                setViewNotes(newViewNotes)
                setNotes(res.body)
            }
        })
    }, [id])

    useEffect(() => {
        getProjectDescription(id).then((res) => {
            if (res.code === 0) {
                setProject(res.body)
                setIsLoading(false)

                if (res.body.linkVideoDescription && res.body.linkVideoDescription !== "") {
                    setLinkVideoDescription(res.body.linkVideoDescription.split(","))
                }
                if (res.body.linkVideoStructure && res.body.linkVideoStructure !== "") {
                    setLinkVideoStructure(res.body.linkVideoStructure.split(","))
                }
                if (res.body.linkVideoExample && res.body.linkVideoExample !== "") {
                    setLinkVideoExample(res.body.linkVideoExample.split(","))
                }
                if (res.body.linkVideoPattern && res.body.linkVideoPattern !== "") {
                    setLinkVideoPattern(res.body.linkVideoPattern.split(","))
                }
                if (res.body.linkVideoCourseContents && res.body.linkVideoCourseContents !== "") {
                    setLinkVideoCourseContents(res.body.linkVideoCourseContents.split(","))
                }
            } else {
                setIsLoading(false)
            }
        })
    }, [id])

    const eventDay = () => {
        let arr = value.toISOString().split("T")
        arr = arr[0].split("-")
        return arr[2] + "/" + arr[1] + "/" + arr[0]
    }


    const handleClose = () => {
        setShow(false)
        setNewDate("")
        setNewDescription("")
        setNewName("")
    };
    const handleShow = () => setShow(true);

    const handleClick = () => {
        if (newName === "") {
            Swal.fire({
                icon: 'error',
                text: 'No puede dejar el nombre en blanco!',
                confirmButtonColor:"#dc3545"
            })
        } else {
            if (newDate === "") {
                Swal.fire({
                    icon: 'error',
                    text: 'Tiene que seleccionar una fecha!',
                    confirmButtonColor:"#dc3545"
                })
            } else {
                setIsLoadingNote(true)
                let body = {
                    name: newName,
                    description: newDescription,
                    date: newDate,
                    idProject: id
                }

                postNotes(body).then((res) => {
                    console.log(res);
                    if(res.code === 0){
                        let arr = notes
                        arr.push(res.body)
                        setNotes(arr)
                        arr = viewNotes
                        if (value.toISOString().split("T")[0] === res.body.date.split("T")[0]) {
                            arr.push(res.body)
                            setViewNotes(arr)
                        }
                        Swal.fire({
                            icon: 'success',
                            text: 'Evento creado exitosamente!',
                            confirmButtonColor:"#ffc107"
                        })
                        setIsLoadingNote(false)
                        handleClose()
                    }
                })
            }
        }

    };

    return (
        <div s="s" className="BriefStudent">
            <Row>
                <Col className="project-info">
                    <div className="name-project">
                        {isLoading ?
                            <div style={{ height: "100%", padding: "8px" }}>
                                <Loading disabledAnimation />
                            </div>
                            :
                            <h1>{project.name}</h1>
                        }
                    </div>

                    {isLoading ?
                        <></>
                        :
                        <div className="col-info">
                            {project.description !== "" || project.filesDescription.length > 0 ?

                                <div className="container-info">

                                    <div className="sticky">
                                        <h1 className="title">Detalles</h1>
                                    </div>

                                    {
                                        project.description !== "" &&
                                        <div className="text-editor" dangerouslySetInnerHTML={{ __html: project.description }}></div>
                                    }

                                    {
                                        project.linkVideoDescription && project.linkVideoDescription !== "" ?
                                            <>
                                                {
                                                    linkVideoDescription.map((res, index) => {
                                                        if (res !== "") {
                                                            let link = res.replace("watch?v=", "embed/")
                                                            return (
                                                                <div className="m-2" key={"description" + index}>
                                                                    <iframe width="384" height="216" src={link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <></>
                                                            )
                                                        }
                                                    })
                                                }
                                            </>
                                            :
                                            <></>
                                    }

                                    {
                                        project.filesDescription.length > 0 &&
                                        <div>
                                            <Row>
                                                {project.filesDescription.map((data, index) => {
                                                    let arr = data.split("/")
                                                    let name = arr[arr.length - 1]
                                                    arr = name.split(".")
                                                    let extension = arr[arr.length - 1]
                                                    let posExtension = listExtension.indexOf(extension)
                                                    if (posExtension < 0) {
                                                        extension = "archivo"
                                                    }
                                                    return (
                                                        <Col key={"col " + index} className="col-file">
                                                            <Row className="card-file">
                                                                <a href={"https://tallervirtualarchivos.s3.amazonaws.com/" + data} target="_blank" rel="noreferrer">
                                                                    <FontAwesomeIcon
                                                                        className="col-view"
                                                                        icon={faEye}
                                                                    />
                                                                </a>

                                                                <Col className="col-img">
                                                                    <img width={50} src={"../../../fileIcon/" + extension + ".png"} alt="archivo" />
                                                                </Col>

                                                                <Col className="col-name">{name}</Col>
                                                            </Row>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </div>
                                    }

                                </div>
                                :
                                <></>

                            }

                            {project.structure !== "" || project.filesStructure.length > 0 ?

                                <div className="container-info">

                                    <div className="sticky">
                                        <h1 className="title">Estructura de la entrega</h1>
                                    </div>
                                    {
                                        project.structure !== "" &&
                                        <div className="text-editor" dangerouslySetInnerHTML={{ __html: project.structure }}></div>
                                    }

                                    {
                                        project.linkVideoStructure && project.linkVideoStructure !== "" ?
                                            <>
                                                {
                                                    linkVideoStructure.map((res, index) => {
                                                        if (res !== "") {
                                                            let link = res.replace("watch?v=", "embed/")
                                                            return (
                                                                <div className="m-2" key={"structure" + index}>
                                                                    <iframe width="384" height="216" src={link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <></>
                                                            )
                                                        }
                                                    })
                                                }
                                            </>
                                            :
                                            <></>
                                    }

                                    {
                                        project.filesStructure.length > 0 &&
                                        <div>
                                            <Row>
                                                {project.filesStructure.map((data, index) => {
                                                    let arr = data.split("/")
                                                    let name = arr[arr.length - 1]
                                                    arr = name.split(".")
                                                    let extension = arr[arr.length - 1]
                                                    let posExtension = listExtension.indexOf(extension)
                                                    if (posExtension < 0) {
                                                        extension = "archivo"
                                                    }
                                                    return (
                                                        <Col key={"col " + index} className="col-file">
                                                            <Row className="card-file">
                                                                <a href={"https://tallervirtualarchivos.s3.amazonaws.com/" + data} target="_blank" rel="noreferrer">
                                                                    <FontAwesomeIcon
                                                                        className="col-view"
                                                                        icon={faEye}
                                                                    />
                                                                </a>

                                                                <Col className="col-img">
                                                                    <img width={50} src={"../../../fileIcon/" + extension + ".png"} alt="archivo" />
                                                                </Col>

                                                                <Col className="col-name">{name}</Col>
                                                            </Row>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </div>
                                    }

                                </div>
                                :
                                <></>

                            }

                            {project.example !== "" || project.filesExample.length > 0 ?

                                <div className="container-info">

                                    <div className="sticky">
                                        <h1 className="title">Ejemplos ilustrativos</h1>
                                    </div>

                                    {
                                        project.example !== "" &&
                                        <div className="text-editor" dangerouslySetInnerHTML={{ __html: project.example }}></div>
                                    }

                                    {
                                        project.linkVideoExample && project.linkVideoExample !== "" ?
                                            <>
                                                {
                                                    linkVideoExample.map((res, index) => {
                                                        if (res !== "") {
                                                            let link = res.replace("watch?v=", "embed/")
                                                            return (
                                                                <div className="m-2" key={"example" + index}>
                                                                    <iframe width="384" height="216" src={link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <></>
                                                            )
                                                        }
                                                    })
                                                }
                                            </>
                                            :
                                            <></>
                                    }

                                    {
                                        project.filesExample.length > 0 &&
                                        <div>
                                            <Row>
                                                {project.filesExample.map((data, index) => {
                                                    let arr = data.split("/")
                                                    let name = arr[arr.length - 1]
                                                    arr = name.split(".")
                                                    let extension = arr[arr.length - 1]
                                                    let posExtension = listExtension.indexOf(extension)
                                                    if (posExtension < 0) {
                                                        extension = "archivo"
                                                    }
                                                    return (
                                                        <Col key={"col " + index} className="col-file">
                                                            <Row className="card-file">
                                                                <a href={"https://tallervirtualarchivos.s3.amazonaws.com/" + data} target="_blank" rel="noreferrer">
                                                                    <FontAwesomeIcon
                                                                        className="col-view"
                                                                        icon={faEye}
                                                                    />
                                                                </a>

                                                                <Col className="col-img">
                                                                    <img width={50} src={"../../../fileIcon/" + extension + ".png"} alt="archivo" />
                                                                </Col>

                                                                <Col className="col-name">{name}</Col>
                                                            </Row>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </div>
                                    }

                                </div>
                                :
                                <></>

                            }

                            {project.pattern !== "" || project.filesPattern.length > 0 ?

                                <div className="container-info">

                                    <div className="sticky">
                                        <h1 className="title">Pauta de evaluación</h1>
                                    </div>

                                    {
                                        project.pattern !== "" &&
                                        <div className="text-editor" dangerouslySetInnerHTML={{ __html: project.pattern }}></div>
                                    }

                                    {
                                        project.linkVideoPattern && project.linkVideoPattern !== "" ?
                                            <>
                                                {
                                                    linkVideoPattern.map((res, index) => {
                                                        if (res !== "") {
                                                            let link = res.replace("watch?v=", "embed/")
                                                            return (
                                                                <div className="m-2" key={"pattern" + index}>
                                                                    <iframe width="384" height="216" src={link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <></>
                                                            )
                                                        }
                                                    })
                                                }
                                            </>
                                            :
                                            <></>
                                    }

                                    {
                                        project.filesPattern.length > 0 &&
                                        <div>
                                            <Row>
                                                {project.filesPattern.map((data, index) => {
                                                    let arr = data.split("/")
                                                    let name = arr[arr.length - 1]
                                                    arr = name.split(".")
                                                    let extension = arr[arr.length - 1]
                                                    let posExtension = listExtension.indexOf(extension)
                                                    if (posExtension < 0) {
                                                        extension = "archivo"
                                                    }
                                                    return (
                                                        <Col key={"col " + index} className="col-file">
                                                            <Row className="card-file">
                                                                <a href={"https://tallervirtualarchivos.s3.amazonaws.com/" + data} target="_blank" rel="noreferrer">
                                                                    <FontAwesomeIcon
                                                                        className="col-view"
                                                                        icon={faEye}
                                                                    />
                                                                </a>

                                                                <Col className="col-img">
                                                                    <img width={50} src={"../../../fileIcon/" + extension + ".png"} alt="archivo" />
                                                                </Col>

                                                                <Col className="col-name">{name}</Col>
                                                            </Row>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </div>
                                    }

                                </div>
                                :
                                <></>

                            }

                            {project.courseContents !== "" || project.filesCourseContents.length > 0 ?

                                <div className="container-info">

                                    <div className="sticky">
                                        <h1 className="title">Contenidos del curso</h1>
                                    </div>

                                    {
                                        project.courseContents !== "" &&
                                        <div className="text-editor" dangerouslySetInnerHTML={{ __html: project.courseContents }}></div>
                                    }

                                    {
                                        project.linkVideoCourseContents && project.linkVideoCourseContents !== "" ?
                                            <>
                                                {
                                                    linkVideoCourseContents.map((res, index) => {
                                                        if (res !== "") {
                                                            let link = res.replace("watch?v=", "embed/")
                                                            return (
                                                                <div className="m-2" key={"courseContents" + index}>
                                                                    <iframe width="384" height="216" src={link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <></>
                                                            )
                                                        }
                                                    })
                                                }
                                            </>
                                            :
                                            <></>
                                    }

                                    {
                                        project.filesCourseContents.length > 0 &&
                                        <div>
                                            <Row>
                                                {project.filesCourseContents.map((data, index) => {
                                                    let arr = data.split("/")
                                                    let name = arr[arr.length - 1]
                                                    arr = name.split(".")
                                                    let extension = arr[arr.length - 1]
                                                    let posExtension = listExtension.indexOf(extension)
                                                    if (posExtension < 0) {
                                                        extension = "archivo"
                                                    }
                                                    return (
                                                        <Col key={"col " + index} className="col-file">
                                                            <Row className="card-file">
                                                                <a href={"https://tallervirtualarchivos.s3.amazonaws.com/" + data} target="_blank" rel="noreferrer">
                                                                    <FontAwesomeIcon
                                                                        className="col-view"
                                                                        icon={faEye}
                                                                    />
                                                                </a>

                                                                <Col className="col-img">
                                                                    <img width={50} src={"../../../fileIcon/" + extension + ".png"} alt="archivo" />
                                                                </Col>

                                                                <Col className="col-name">{name}</Col>
                                                            </Row>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </div>
                                    }

                                </div>
                                :
                                <></>

                            }
                        </div>
                    }

                </Col>

                <Col className="calendar">
                    <div className="container-info">

                        <h1 className="title">Calendario <Button variant="secondary" onClick={handleShow}>Agregar evento</Button></h1>
                        <Row className="justify-content-center">
                            <Col style={{ flex: "0 0 auto", width: "348px" }} className="m-2">

                                <Calendar
                                    onChange={onChange}
                                    value={value}
                                />
                            </Col>
                            <Col className="m-2">
                                <h4 style={{ color: "rgb(var(--color-25))" }}>Eventos del {eventDay()}</h4>
                                {
                                    isLoadingNotes ?
                                        <div style={{ height: "100%", padding: "8px" }}>
                                            <Loading disabledAnimation />
                                        </div>
                                        :
                                        viewNotes.length === 0 ?
                                            <div className="note">
                                                <p>- Sin eventos</p>
                                            </div>
                                            :
                                            <div>
                                                {viewNotes.map((res, index) => {
                                                    return (
                                                        <div className="note" key={index}>
                                                            <p>Nombre: {res.name}</p>
                                                            <p>Hora termino: {res.date.split("T")[1].split("Z")[0]}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                }
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Modal show={show} centered style={{ color: "rgb(var(--color-22))" }}>
                <Modal.Header>
                    <Modal.Title>Nuevo evento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Fecha termino:</label>
                    <Form.Control disabled={isLoadingNote} className="mb-2" type="datetime-local" onChange={(e) => { setNewDate(e.target.value + ":59"); }} />
                    <label>Nombre:</label>
                    <Form.Control disabled={isLoadingNote} className="mb-2" value={newName} onChange={(e) => { setNewName(e.target.value); }} />
                    <label>Descripcion:</label>
                    <FormControl disabled={isLoadingNote} as="textarea" value={newDescription} onChange={(e) => { setNewDescription(e.target.value); }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" disabled={isLoadingNote} onClick={handleClose}>Cancelar</Button>
                    {
                        !isLoadingNote ?
                            <Button variant="warning" onClick={handleClick}>Enviar</Button>
                            :
                            <Button variant="warning" disabled><Loading /></Button>
                    }

                </Modal.Footer>

            </Modal>
        </div >
    )
}

export default BriefStudent