import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { Col, Row, Button } from "react-bootstrap"
import { useParams, Link, Outlet } from "react-router-dom"
import { Api } from "../../AWS/aws"
import Loading from "../../Components/Loading"
import dateFormat from "../../Assets/Js/dateFormat"
import "./SubmissionStudent.css"

const SubmissionStudent = ({ user }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [mySubmission, setMySubmission] = useState([])
    const [submissions, setSubmissions] = useState([])
    const [submissionsOnDate, setSubmissionsOnDate] = useState([])
    const [submissionsOutOfDate, setSubmissionsOutOfDate] = useState([])
    const [submission, setSubmission] = useState({})

    const { id } = useParams()

    const getEntregas = (id) => {
        return Api({
            path: `/student/entregas/${id}`
        })
    }

    const getSubmissionDescription = (id) => {
        return Api({
            path: `/project/submission/description/${id}`
        })
    }

    useEffect(() => {
        getSubmissionDescription(id).then(res => {
            if (res.code === 0) {
                if (res.body.onDate.length > 0) {
                    let submissionDescription = null
                    res.body.onDate.forEach(element => {
                        if (!submissionDescription) {
                            submissionDescription = element
                        } else {
                            if (submissionDescription.num > element.num) {
                                submissionDescription = element
                            }
                        }
                    });
                    setSubmission(submissionDescription)
                }
                setSubmissionsOnDate(res.body.onDate)
                setSubmissionsOutOfDate(res.body.outOfDate)
            }
        })
        getEntregas(id).then((res) => {
            if (res.code === 0) {
                let arr = res.body.entregas.sort(function (a, b) { return a.submission.num - b.submission.num });
                setIsLoading(false)
                setMySubmission(res.body.miEntrega)
                setSubmissions(arr)
            } else {
                setIsLoading(false)
            }
        })
    }, [id])

    return (
        <div className="SubmissionStudent">
            <div className="container-info">
                <h1 className="title">Mis entregas {submissionsOnDate.length > 0 ? <Button as={Link} variant="secondary" to={"submit?idSubmission=" + submission.id}>Agregar entrega</Button> : <></>}</h1>
                {isLoading ?
                    <Loading disabledAnimation />
                    :
                    mySubmission.length === 0 ?
                        <p>No haz subido ninguna entrega</p>
                        :
                        <Row className="row-center">
                            {mySubmission.map((data, index) => {
                                return (
                                    <Col key={index} className="col-submission">
                                        <Link to={data.id} style={{ color: "rgb(var(--color-7))" }} className={"text-decoration-none"}>
                                        {
                                                                data.portada?
                                                                <img style={{height:"150px"}} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + data.portada} alt="archivo" />
                                                                :
                                                                <></>
                                                            }
                                            <p>Entrega {data.submission.num}</p>
                                            <p>{dateFormat("date", data.date)}</p>
                                        </Link>
                                    </Col>
                                )
                            })}
                        </Row>
                }
            </div>
            <div className="container-info">
                <h1 className="title">Otras entregas</h1>
                {isLoading ?
                    <Loading disabledAnimation />
                    :
                    submissions.length === 0 ?
                        <p>No hay otras entrega</p>
                        :
                        <Row className="row-center">
                            {submissions.map((data, index) => {
                                return (
                                    <Col key={index} className="col-submission">
                                        <Link to={data.id} style={{ color: "rgb(var(--color-7))" }} className={"text-decoration-none"}>
                                        {
                                                                data.portada?
                                                                <img style={{height:"150px"}} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + data.portada} alt="archivo" />
                                                                :
                                                                <></>
                                                            }
                                            <div className="group-name"><p>{data.group.name}</p></div>
                                            <p>Entrega {data.submission.num}</p>
                                            <p>{dateFormat("date", data.date)}</p>
                                        </Link>
                                    </Col>
                                )
                            })}
                        </Row>
                }
            </div>
            <Outlet />
        </div>
    )
}

export default SubmissionStudent