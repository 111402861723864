import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { Api } from "../../AWS/aws"
import { Row, Container, Card, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loading from "../../Components/Loading"
import imgDefault from "../../Assets/Img/default.png"
import dateFormat from "../../Assets/Js/dateFormat"
import "./MyProjectsAssistant.css"

const MyProjectsAssistant = ({ user }) => {

    const [projects, setProjects] = useState([])
    const [courses, setCourses] = useState([])

    const [isLoadingProjects, setIsLoadingProjects] = useState(true)
    const [isLoadingCourses, setIsLoadingCourses] = useState(true)

    const getProjects = () => {
        return Api({
            path: "/teacher/projects"
        })
    }

    useEffect(() => {
        getProjects().then((res) => {
            if (res.code === 0) {
                setProjects(res.body)
                setIsLoadingProjects(false)
            } else {
                setIsLoadingProjects(false)
            }
        })
    }, [])

    return (
        <div className="MyProjectsAssistant">
            <Row>
                <h2 className="titulo animate__animated animate__fadeInDown">Mis proyectos</h2>
                {projects.length === 0 ?
                    isLoadingProjects ?
                        <Loading />
                        :
                        <Container className="card-margin">
                            <Row>
                                <p style={{color:"rgb(var(--color-22))"}}>No estas participando en ningun proyecto</p>
                            </Row>
                        </Container>
                    :
                    <Container className="card-margin">
                        <Row className="justify-content-around-810 m-0">
                            {projects.map((project, index) => {
                                let name = project.nameTeacher
                                let arrName = ["", "", "", ""]

                                if (name) {
                                    arrName = name.split(" ")
                                }
                                return (
                                    <Col xs={3} style={{ width: "316px", marginRight: "10px", marginLeft: "10px" }} key={"col1" + index}>
                                        <Link className="text-decoration-none text-dark d-flex justify-content-center m-auto" key={"link" + index} to={"/project/" + project.idProyect} style={{ height: "100%" }}>
                                            <Card key={"card" + index} className="Carta animate__animated animate__fadeInLeft" style={{ width: '18rem', minHeight: "280px" }}>
                                                {project.urlImg ?
                                                    <img key={"img1" + index} className="Carta-imagen" src={"https://tallervirtualarchivos.s3.amazonaws.com/" + project.urlImg} alt="imagen" />
                                                    :
                                                    <img key={"img1" + index} className="Carta-imagen" src={imgDefault} alt="imagen" />
                                                }
                                                <Card.Body key={"card.body" + index}>
                                                    <Row key={"row2" + index}>
                                                        <Col key={"col5" + index}><p><b>- {project.nameProject}</b></p></Col>
                                                    </Row>
                                                    <Row key={"row1" + index}>
                                                        <Col key={"col3" + index}><p>- {project.nameCourse}</p></Col>
                                                    </Row>
                                                    <Row key={"row3" + index}>
                                                        <Col key={"col3" + index}><p>- {arrName[0] + " " + arrName[arrName.length - 2]}</p></Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Link>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Container>
                }
            </Row>
        </div>
    )
}

export default MyProjectsAssistant