import React from "react";
import { StyleSheet, Document, Page, Text, View, Image } from "@react-pdf/renderer";
import logo2 from "../Assets/Img/logo2.png"
import dateFormat from "../Assets/Js/dateFormat";

const PDF = ({ data }) => {
    data.groups = data.groups.sort(function (a, b) { return a.name.localeCompare(b.name) });
    for (let i = 0; i < data.groups.length; i++) {
        data.groups[i].students = data.groups[i].students.sort(function (a, b) { return a.name.localeCompare(b.name) });
    }

    if (data.submission) {
        data.submission = data.submission.sort(function (a, b) { return a.num - b.num });
    }
    if (data.advance) {
        data.advance = data.advance.sort(function (a, b) { return a.num - b.num });
    }

    return (
        <Document title={"Resumen " + data.name} >

            <Page size={"FOLIO"} >
                <Header fecha={dateFormat("timeDate", data.dateResume)} />
                <View style={styles.title}>
                    <Text>{data.name}</Text>
                </View>
                <View style={styles.todo}>
                    <Text>Fecha de creación proyecto: {dateFormat("timeDate", data.date)}</Text>
                    <Text>Curso: {data.course.name}</Text>
                    <Text>Profesor:{data.teacher.name}</Text>
                    {
                        data.assistant && data.assistant.length > 0 ? (
                            <>
                                {data.assistant.map((assistant, index) => (
                                    <Text key={index}>
                                        {`Ayudante ${index + 1}: ${assistant.name}`}
                                    </Text>
                                ))}
                            </>
                        ) : null
                    }
                    <Text>Cantidad de grupos:{data.groups.length}</Text>

                    {
                        data.advance &&
                        data.advance.map((res, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Text>Fecha avance {res.num}: {dateFormat("timeDate", res.endDate)}</Text>
                                </React.Fragment>
                            )
                        })
                    }

                    {
                        data.submission &&
                        data.submission.map((res, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Text>Fecha entrega {res.num}: {dateFormat("timeDate", res.endDate)}</Text>
                                </React.Fragment>
                            )
                        })
                    }



                </View>
            </Page>


            {
                data.studentsWithoutGroup.length>0?
                <Page size={"FOLIO"}>
                <Header />

                <View style={styles.subTitle}>
                    <Text>Estudiantes sin grupo</Text>
                </View>

                {
                    data.studentsWithoutGroup.map((student, index) => {

                        return (
                            <React.Fragment size={"FOLIO"} key={index}>
                                <View style={styles.subTitle2}>
                                    <Text>Estudiante: {index + 1}</Text>
                                </View>
                                <View style={styles.todo}>
                                    <Text>Nombre: {student.name}</Text>
                                    <Text>Rut: {student.id}</Text>
                                </View>
                            </React.Fragment>
                        )
                    })
                }

            </Page>
:
<></>
            }

            <Page size={"FOLIO"}>
                <Header />

                <View style={styles.subTitle}>
                    <Text>Acciones totales realizadas</Text>
                </View>

                <View style={styles.todo}>
                    <Text>Comentarios realizados a avances: {data.totals.comentarios_avances}</Text>
                    <Text>Comentarios realizados a entregas: {data.totals.comentarios_entregas}</Text>
                    <Text>Avances vistos por alumnos: {data.totals.avances_vistos}</Text>
                    <Text>Entregas vistas por alumnos: {data.totals.entregas_vistas}</Text>
                    <Text>Recursos en moodboard: {data.totals.recursos_moodboard}</Text>
                    <Text>Vistas a los moodboards: {data.totals.visualizaciones_moodboard}</Text>
                    <Text>Moodboard favoritos: {data.totals.moodboard_favoritos}</Text>
                    <Text>Interacciones con el chat: {data.totals.interacciones_chat}</Text>
                    <Text>Avances realizados: {data.totals.avances_realizados}</Text>
                    <Text>Entregas realizadas: {data.totals.entregas_realizadas}</Text>
                </View>
                

            </Page>

            {
                data.groups.map((grupo, indexGrupo) => {
                    return (
                        <React.Fragment key={indexGrupo}>
                            {
                                grupo.students.map((student, index) => {

                                    return (
                                        <Page size={"FOLIO"} key={index}>
                                            <Header />
                                            <View style={styles.subTitle}>
                                                <Text>{grupo.name}</Text>
                                            </View>
                                            <View style={styles.todo}>
                                                <Text>Nombre: {student.name}</Text>
                                                <Text>Rut: {student.id}</Text>
                                                <Text>Comentarios realizados a avances: {student.comentarios_a_avances}</Text>
                                                <Text>Comentarios realizados a entregas: {student.comentarios_a_entregas}</Text>
                                                <Text>Avances vistos por alumno: {student.avances_vistos}</Text>
                                                <Text>Entregas vistas por alumno: {student.entregas_vistas}</Text>
                                                <Text>Recursos en moodboard: {student.recursos_moodboard}</Text>
                                                <Text>Vistas al moodboard del alumno: {student.vistas_a_su_moodboard}</Text>
                                                <Text>Vistas del alumno a otros moodboard: {student.vistas_a_otros_moodboards}</Text>
                                                <Text>Moodboard favoritos: {student.moodboard_favoritos}</Text>
                                                <Text>Interacciones con el chat: {student.interacciones_chat}</Text>
                                                <Text>Avances realizados como grupo: {student.avances_realizados}</Text>
                                                <Text>Entregas realizadas como grupo: {student.entregas_realizadas}</Text>
                                            </View>
                                        </Page>
                                    )
                                })
                            }
                        </React.Fragment>
                    )
                })
            }

        </Document>
    );
};

const Header = ({ fecha }) => {

    return (
        <>
            <View style={styles.row}>
                <View style={{ width: "56%" }}>
                    {
                        fecha &&
                        <View style={styles.fecha}>
                            <Text>Fecha Resumen</Text>
                            <Text>{fecha}</Text>
                        </View>
                    }
                </View>
                <View style={{ width: "44%" }}>
                    <Image style={{ height: "40px", width: "211px", margin: "20px" }} src={logo2}></Image>
                </View>
            </View>

        </>
    );
};

const styles = StyleSheet.create({
    todo: {
        margin: "60px",
        marginTop: "0px",
        fontSize: "15px"
    },
    fecha: {
        marginBottom: "10px",
        marginTop: "10px",
        height: "40px",
        width: "70%",
        textAlign: "center",
        margin: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid back",
        borderRadius: "5px",
        fontSize: "12px"
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    col: {
        flex: "1 0 0%"
    },
    title: {
        textAlign: "center",
        margin: "10px",
        marginBottom: "20px",
        fontSize: "30px",
        fontWeight: "bold"
    },
    subTitle: {
        textAlign: "center",
        margin: "10px",
        fontSize: "25px"
    },
    subTitle2: {
        textAlign: "left",
        margin: "10px",
        marginLeft: "40px",
        fontSize: "20px"
    },
    info: {
        width: "70%",
        padding: "5px",
        paddingLeft: "15px",
        textAlign: "left",
        margin: "10px",
        border: "1px solid back",
        borderRadius: "5px"
    },
    grafico: {
        height: "400px",
        margin: "10px",
        border: "1px solid back",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "space-evenly"
    }
})

export default PDF;