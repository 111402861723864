import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { Api } from "../../AWS/aws"
import { Row, Container, Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loading from "../../Components/Loading"
import imgDefault from "../../Assets/Img/default.png"
import "./MyProjectsStudent.css"

const MyProjectsStudent = ({ user }) => {

    const [projects, setProjects] = useState([])
    const [projectsAssistant, setProjectsAssistant] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingProjects, setIsLoadingProjects] = useState(true)

    const getProjects = () => {
        return Api({
            path: "/user/projects"
        })
    }
    const getProjectsAssistant = () => {
        return Api({
            path: "/teacher/projects"
        })
    }
    useEffect(() => {
        getProjects().then((res) => {
            if (res.code === 0) {
                setProjects(res.body)
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        })
        getProjectsAssistant().then((res) => {
            if (res.code === 0) {
                setProjectsAssistant(res.bodyAssistant)
                setIsLoadingProjects(false)
            } else {
                setIsLoadingProjects(false)
            }
        })
    }, [])

    return (
        <div className="MyProjectsStudent">
            <Row>
                <h2 className="titulo animate__animated animate__fadeInDown">Mis proyectos </h2>
                {projects.length === 0 ?
                    isLoading ?
                        <Loading />
                        :
                        <Container className="card-margin">
                            <Row>
                                <p style={{color:"rgb(var(--color-22))"}}>No estas participando en ningun proyecto</p>
                            </Row>
                        </Container>
                    :
                    <Container className="card-margin">
                        <Row className="justify-content-around-810 m-0">
                            {projects.map((project, index) => {
                                let name = project.nameTeacher
                                if (name ) {
                                        const parts = name.trim().split(" "); // Divide por espacios y elimina espacios extra.
                                    if (parts.length === 1) {
                                        // Si solo hay un nombre, úsalo tal cual.
                                        name = parts[0];
                                    } else if (parts.length === 2) {
                                        // Si hay un nombre y un apellido, úsalos tal cual.
                                        name = `${parts[0]} ${parts[1]}`;
                                    } else {
                                        // Si hay más de dos palabras, toma el primer nombre y el primer apellido.
                                        name = `${parts[0]} ${parts[parts.length - 2]}`;
                                    }
                                }
                                return (
                                    <Col xs={3} style={{ width: "316px", marginRight: "10px", marginLeft: "10px" }} key={"col1" + index}>
                                        <Link className="text-decoration-none text-dark d-flex justify-content-center m-auto" key={"link" + index} to={"/project/" + project.idProyect} style={{ height: "100%" }}>
                                            <Card key={"card" + index} className="Carta animate__animated animate__fadeInLeft" style={{ width: '18rem', minHeight: "320px" }}>
                                                {project.urlImg ?
                                                    <img key={"img1" + index} className="Carta-imagen" src={"https://tallervirtualarchivos.s3.amazonaws.com/" + project.urlImg} alt="imagen" />
                                                    :
                                                    <img key={"img1" + index} className="Carta-imagen" src={imgDefault} alt="imagen" />
                                                }
                                                <Card.Body key={"card.body" + index}>
                                                    <Row key={"row1" + index}>
                                                        <Col key={"col3" + index}><p><b>- {project.nameCourse}</b></p></Col>
                                                    </Row>
                                                    <Row key={"row2" + index}>
                                                        <Col key={"col5" + index}><p>- {project.nameProject}</p></Col>
                                                    </Row>
                                                    <Row key={"row4" + index}>
                                                        <Col key={"col9" + index}><p>- {name}</p></Col>
                                                    </Row>
                                                    <Row key={"row3" + index}>
                                                        <Col key={"col3" + index}><p>- {project.completed}/{project.total} tareas completadas</p></Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Link>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Container>
                }
            </Row>
            {
                projectsAssistant.length !== 0 ??
                <Row className="assistant">
                    <h2 className="titulo animate__animated animate__fadeInDown">Mis proyectos (Ayudante)</h2>
                    {projectsAssistant.length === 0 ?
                        isLoadingProjects ?
                            <Loading />
                            :
                            <Container className="card-margin">
                                <Row>
                                    <p style={{color:"rgb(var(--color-22))"}}>No estas participando en ningun proyecto</p>
                                </Row>
                            </Container>
                        :
                        <Container className="card-margin">
                            <Row className="justify-content-around-810 m-0">
                                {projectsAssistant.map((project, index) => {
                                    
                                let name = project.nameTeacher
                                if (name ) {
                                        const parts = name.trim().split(" "); // Divide por espacios y elimina espacios extra.
                                    if (parts.length === 1) {
                                        // Si solo hay un nombre, úsalo tal cual.
                                        name = parts[0];
                                    } else if (parts.length === 2) {
                                        // Si hay un nombre y un apellido, úsalos tal cual.
                                        name = `${parts[0]} ${parts[1]}`;
                                    } else {
                                        // Si hay más de dos palabras, toma el primer nombre y el primer apellido.
                                        name = `${parts[0]} ${parts[parts.length - 2]}`;
                                    }
                                }
                                    return (
                                        <Col xs={3} style={{ width: "316px", marginRight: "10px", marginLeft: "10px" }} key={"col1" + index}>
                                            <Link className="text-decoration-none text-dark d-flex justify-content-center m-auto" key={"link" + index} to={"/project/" + project.idProyect} style={{ height: "100%" }}>
                                                <Card key={"card" + index} className="Carta animate__animated animate__fadeInLeft" style={{ width: '18rem', minHeight: "280px" }}>
                                                    {project.urlImg ?
                                                        <img key={"img1" + index} className="Carta-imagen" src={"https://tallervirtualarchivos.s3.amazonaws.com/" + project.urlImg} alt="imagen" />
                                                        :
                                                        <img key={"img1" + index} className="Carta-imagen" src={imgDefault} alt="imagen" />
                                                    }
                                                    <Card.Body key={"card.body" + index}>
                                                        <Row key={"row2" + index}>
                                                            <Col key={"col5" + index}><p><b>- {project.nameProject}</b></p></Col>
                                                        </Row>
                                                        <Row key={"row1" + index}>
                                                            <Col key={"col3" + index}><p>- {project.nameCourse}</p></Col>
                                                        </Row>
                                                        <Row key={"row3" + index}>
                                                            <Col key={"col3" + index}><p>- {name}</p></Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Link>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Container>
                    }
                </Row>
            }
        </div>
    )
}

export default MyProjectsStudent