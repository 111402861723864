import React, { Component } from "react"
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Button, Col, Modal, Row, FormControl } from "react-bootstrap";
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client, DeleteObjectCommand } from '@aws-sdk/client-s3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import listExtension from "../../Assets/Js/listExtension"
import Countdown from "../../Components/Countdown";
import Loading from "../../Components/Loading";
import { Api } from "../../AWS/aws"
import Swal from 'sweetalert2'
import "./SubmitSubmission.css"

function withParams(Component) {
    return props => <Component {...props} params={useParams()} navigate={useNavigate()} />;
}
class SubmitSubmission extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoadingSubmissionDescription: true,
            isSaving: false,
            message: "",
            comentario: "",
            files: [],
            loadingFile: [],
            parallelUploads3: [],
            date: Date.now(),
            submissionDescription: {},
            portada:false,
            loadingPortada:false,
            parallelUploads3Portada:false,
            isLoadingPortada:false
        };
    }

    getSubmission(id) {
        return Api({
            path: `/entrega/${id}`
        })
    }

    postSubmission(body) {
        return Api({
            path: `/student/entrega`,
            method: "POST",
            body: body
        })
    }

    async componentDidMount() {
        const valores = window.location.search
        const urlParams = new URLSearchParams(valores);
        const idSubmission = urlParams.get('idSubmission')

        this.getSubmission(idSubmission).then(res => {
            if (res.code === 0) {
                this.setState({ isLoadingSubmissionDescription: false, submissionDescription: res.body })
            }
        })
    }

    saveSubmission = () => {
        this.setState({ isSaving: true })

        if (!this.state.portada || this.state.loadingPortada.processing) {
            Swal.fire({
                icon: 'error',
                text: "Tiene que subir una portada",
                confirmButtonColor: "#dc3545"
            })
            this.setState({ isSaving: false })
            return
        }
        if (this.state.files.length === 0) {
            Swal.fire({
                icon: 'error',
                text: "Tiene que subir almenos un archivo",
                confirmButtonColor: "#dc3545"
            })
            this.setState({ isSaving: false })
            return
        }

        let body = {
            submissionDescription: this.state.submissionDescription,
            files: this.state.files,
            comment: this.state.comentario,
            portada:this.state.portada
        }

        let ok = true

        this.state.loadingFile.forEach(element => {
            if(element.processing || element.value < element.total){
                //elemento cargando
                Swal.fire({
                    icon: 'error',
                    text: "Existen archivos que no se han subido completamente",
                    confirmButtonColor: "#dc3545"
                })
                ok = false
            }
        });

        if(ok){
            this.postSubmission(body).then((res) => {
                this.setState({ isSaving: false })
                if(res.code === 0){
                    Swal.fire({
                        icon: 'success',
                        text: 'Entrega creada exitosamente!',
                        confirmButtonColor: "#ffc107"
                    }).then(() => {
                        window.location = `/project/${this.props.params.id}/submission`
                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: "Error al subir la entrega",
                        confirmButtonColor: "#dc3545"
                    })
                }
                
    
            })
        }else{
            this.setState({ isSaving: false })
        }

        

    }

    uploadS3 = async (file, key, fun, portada=false) => {

        const target = {
            Bucket: "tallervirtualarchivos",
            Key: key,
            Body: file,
            ACL: "public-read",
            ContentType: file.type
        }
        const creds = {
            accessKeyId: "AKIA2T5OXYWGE4A6BCMS",
            secretAccessKey: "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",
        }
        try {
            const parallelUploads3 = new Upload({
                client: new S3Client({
                    region: "us-east-1",
                    credentials: creds
                }),
                partSize: 1024 * 1024 * 20,
                params: target,
            })
            if(portada){


            parallelUploads3.on("httpUploadProgress", (process) => {
                fun(process)
            })

            this.setState({ parallelUploads3Portada: parallelUploads3 })

            }else{
                let arr = this.state.parallelUploads3
                let pos = this.state.files.indexOf(key)
                if (pos !== -1) {
                    arr[pos] = parallelUploads3
                    this.setState({ parallelUploads3: arr })
                }
    
                parallelUploads3.on("httpUploadProgress", (process) => {
                    fun(process)
                })
            }

            await parallelUploads3.done()
            return true
        } catch (e) {
            console.log(e);
            return false
        }
    }

    deleteS3 = (key) => {

        const target = {
            Bucket: "tallervirtualarchivos",
            Key: key
        }

        const creds = {
            accessKeyId: "AKIA2T5OXYWGE4A6BCMS",
            secretAccessKey: "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",
        }

        const client = new S3Client({
            region: "us-east-1",
            credentials: creds
        })

        const command = new DeleteObjectCommand(target)

        client.send(command).then((res) => {
            console.log(res);
        })
    }

    delete = (key, index) => {

        if (this.state.loadingFile[index]) {
            if (this.state.loadingFile[index].processing) {
                //se esta cargando archivo
                let arr = this.state.parallelUploads3
                arr[index].abort()
                arr.splice(index, 1)
                this.setState({ parallelUploads3: arr })
            } else {
                if (this.state.loadingFile[index].value === this.state.loadingFile[index].total) {
                    //ya se cargo el archivo
                    this.deleteS3(key)
                } else {
                    //se esta cargando archivo
                    let arr = this.state.parallelUploads3
                    arr[index].abort()
                    arr.splice(index, 1)
                    this.setState({ parallelUploads3: arr })
                }
            }
        } else {

        }
    }

    files = (eventFiles,portada=false) => {
        let date = this.state.date
        let files = this.state.files
        let student = this.props.user
        let submissionDescription = this.state.submissionDescription
        let idProject = this.props.params.id

        for (let index = 0; index < eventFiles.length; index++) {
            let key = ""
            if(portada){
                key = student.id + "/" + idProject + "/" + submissionDescription.id + "/" + date + "/portada/" + eventFiles[index].name
                this.setState({portada:key, loadingPortada:{ processing: true }, isLoadingPortada: true})
                this.uploadS3(eventFiles[index], key, (data) => {
                    this.setState({ loadingPortada: { value: data.loaded, total: data.total }})
                },true).then((res) => {
                    this.setState({ isLoadingPortada: false })
                })
            }else{
            key = student.id + "/" + idProject + "/" + submissionDescription.id + "/" + date + "/" + eventFiles[index].name
            if (files.indexOf(key) < 0) {
                files.push(key)
                let loadingFile = this.state.loadingFile

                loadingFile[files.indexOf(key)] = { processing: true }
                this.setState({ loadingFile: loadingFile })

                this.uploadS3(eventFiles[index], key, (data) => {
                    let i = files.indexOf(key)
                    let loadingFile = this.state.loadingFile
                    loadingFile[i] = { value: data.loaded, total: data.total }
                    this.setState({ loadingFile: loadingFile })
                }).then((res) => {
                    console.log(res);
                })
            }
        }
        }

        this.setState({ files: files })
    }

    renderImgFile(ruta, index) {

        if (this.state.loadingFile[index]) {
            if (this.state.loadingFile[index].processing) {
                return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
            } else {
                if (this.state.loadingFile[index].value === this.state.loadingFile[index].total) {
                    return (<img width={50} src={ruta} alt="archivo" />)
                } else {
                    return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
                }
            }
        } else {
            return (<img width={50} src={ruta} alt="archivo" />)
        }

    }


    deleteImg = (key) => {

        if (this.state.loadingPortada) {
            if (this.state.loadingPortada.processing) {
                //se esta cargando archivo
                let parallelUploads3Img = this.state.parallelUploads3Portada
                parallelUploads3Img.abort()
                parallelUploads3Img = null
                this.setState({ parallelUploads3Portada: parallelUploads3Img })
            } else {
                if (this.state.loadingPortada.value === this.state.loadingPortada.total) {
                    //ya se cargo el archivo
                    this.deleteS3(key)
                } else {
                    //se esta cargando archivo
                    let parallelUploads3Img = this.state.parallelUploads3Portada
                    parallelUploads3Img.abort()
                    parallelUploads3Img = null
                    this.setState({ parallelUploads3Portada: parallelUploads3Img })
                }
            }
        } else {

        }
    }


    render() {

        let files = this.state.files
        let loadingFile = this.state.loadingFile

        let days = 0
        let hours = 0
        let minutes = 0
        let seconds = 0

        if (this.state.submissionDescription.endDate) {
            let endDate = this.state.submissionDescription.endDate.replace("Z", "")
            endDate = new Date(endDate)
            let date = new Date()
            date = endDate.getTime() - date.getTime()

            days = Math.trunc(date / (1000 * 60 * 60 * 24))
            hours = Math.trunc(date / (1000 * 60 * 60) % 24)
            minutes = Math.trunc(date / (1000 * 60) % 60)
            seconds = Math.trunc(date / (1000) % 60)
        }

        return (
            <div className="SubmitSubmission">
                <Modal show={true} className="modal SubmitSubmission" size="lg" centered >
                    {this.state.isLoadingSubmissionDescription ?
                        <div style={{ marginTop: "50px", marginBottom: "50px" }}>
                            <Loading variant="dark" disabledAnimation />
                        </div>
                        :
                        <>
                            <Modal.Header>
                                <Modal.Title>Entrega {this.state.submissionDescription.num}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <Row className="mb-3">
                                    <Col style={{ maxWidth: "150px" }}>
                                        <span>Tiempo restante:</span>
                                    </Col>
                                    <Col>
                                        <Countdown startingDays={days} startingHours={hours} startingMinutes={minutes} startingSeconds={seconds} />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col style={{ maxWidth: "150px" }}>
                                        <span>Portada:</span>
                                    </Col>
                                    
                                </Row>
                                {
                                    this.state.portada?
                                    
                                    <Row className='mb-3' style={{justifyContent:"left",marginLeft:150}}>
                                        <div style={{position:"relative", width:"150px", display:"flex",justifyContent:"center"}}>
                                        <FontAwesomeIcon
                                                            onClick={(e) => {
                                                                this.deleteImg(this.state.imagen)
                                                                this.setState({ portada: false })
                                                            }}
                                                            className="col-delete-2"
                                                            icon={faXmark}
                                                        />
                                                        {this.state.isLoadingPortada ?
                                                            this.state.loadingPortada ?
                                                                this.state.loadingPortada.processing ?
                                                                    <div style={{ width: 100 }}>
                                                                        <CircularProgressbar
                                                                            value={0}
                                                                            text={` Cargando `}
                                                                            styles={
                                                                                buildStyles({
                                                                                    textColor: "#6c757d",
                                                                                    pathColor: "#6c757d"
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div style={{ width: 100 }}>
                                                                        <CircularProgressbar
                                                                            value={this.state.loadingPortada.value}
                                                                            maxValue={this.state.loadingPortada.total}
                                                                            text={`${Math.round(this.state.loadingPortada.value * 100 / this.state.loadingPortada.total)}%`}
                                                                            styles={
                                                                                buildStyles({
                                                                                    textColor: "#6c757d",
                                                                                    pathColor: "#6c757d"
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                :
                                                                <>alo</>
                
                                                            :
                                                            <img style={{width:"150px"}} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + this.state.portada} alt="archivo" />
                                                        }
                                        </div>
                                    </Row>
                                    
                                    :
                                    <Row className="mb-3">
                                <Col>
                                        <div
                                            className="drop-area"
                                            id="drop-area-portada"
                                            onDragOver={(e) => {
                                                e.preventDefault()
                                                let dropArea = document.getElementById("drop-area-portada")
                                                dropArea.querySelector("h4").textContent = "Suelta para subir tu portada"
                                                dropArea.classList.add("active")
                                            }}
                                            onDragLeave={(e) => {
                                                e.preventDefault()
                                                let dropArea = document.getElementById("drop-area-portada")
                                                dropArea.querySelector("h4").textContent = "Arrastra aquí tu portada"
                                                dropArea.classList.remove("active")
                                            }}
                                            onDrop={(e) => {
                                                e.preventDefault()
                                                this.files(e.dataTransfer.files)
                                                let dropArea = document.getElementById("drop-area-portada")
                                                dropArea.querySelector("h4").textContent = "Arrastra aquí tu portada"
                                                dropArea.classList.remove("active")
                                            }}
                                        >
                                            <h4>Arrastra aquí tu portada</h4>
                                            <span>O</span>
                                            <Button
                                                className="mt-2"
                                                variant="secondary"
                                                disabled={this.state.isSaving}
                                                onClick={(e) => {
                                                    let input = document.getElementById("inputFile-Portada")
                                                    console.log(input)
                                                    input.click()
                                                }}
                                            >
                                                Selecciona tu portada
                                            </Button>
                                            <input
                                                disabled={this.state.isSaving}
                                                id="inputFile-Portada"
                                                type="file"
                                                hidden
                                                accept="image/*"
                                                onChange={(e) => {
                                                    this.files(e.target.files, true)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                }
                                <Row className="mb-3">
                                    <Col style={{ maxWidth: "150px" }}>
                                        <span>Comentarios:</span>
                                    </Col>
                                    <Col>
                                        <FormControl disabled={this.state.isSaving} as="textarea" rows={3} value={this.state.comentario} onChange={(event) => this.setState({ comentario: event.target.value })} />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <div
                                            className="drop-area"
                                            id="drop-area"
                                            onDragOver={(e) => {
                                                e.preventDefault()
                                                let dropArea = document.getElementById("drop-area")
                                                dropArea.querySelector("h4").textContent = "Suelta para subir tus archivos"
                                                dropArea.classList.add("active")
                                            }}
                                            onDragLeave={(e) => {
                                                e.preventDefault()
                                                let dropArea = document.getElementById("drop-area")
                                                dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                dropArea.classList.remove("active")
                                            }}
                                            onDrop={(e) => {
                                                e.preventDefault()
                                                this.files(e.dataTransfer.files)
                                                let dropArea = document.getElementById("drop-area")
                                                dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                dropArea.classList.remove("active")
                                            }}
                                        >
                                            <h4>Arrastra aquí tus archivos</h4>
                                            <span>O</span>
                                            <Button
                                                className="mt-2"
                                                variant="secondary"
                                                disabled={this.state.isSaving}
                                                onClick={(e) => {
                                                    let input = document.getElementById("inputFile")
                                                    input.click()
                                                }}
                                            >
                                                Selecciona tus archivos
                                            </Button>
                                            <input
                                                disabled={this.state.isSaving}
                                                id="inputFile"
                                                type="file"
                                                multiple
                                                hidden
                                                value={""}
                                                onChange={(e) => {
                                                    this.files(e.target.files)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <Row>
                                                {files.map((data, index) => {
                                                    let arr = data.split("/")
                                                    let name = arr[arr.length - 1]
                                                    arr = name.split(".")
                                                    let extension = arr[arr.length - 1]
                                                    let posExtension = listExtension.indexOf(extension)
                                                    if (posExtension < 0) {
                                                        extension = "archivo"
                                                    }
                                                    return (
                                                        <Col key={"col " + index} className="col-file">
                                                            <Row className="card-file">
                                                                <FontAwesomeIcon
                                                                    onClick={(e) => {
                                                                        if (!this.state.isSaving) {
                                                                            this.delete(files[index], index)
                                                                            files.splice(index, 1)
                                                                            loadingFile.splice(index, 1)
                                                                            this.setState({ files: files, loadingFile: loadingFile })
                                                                        }
                                                                    }}
                                                                    className="col-delete"
                                                                    icon={faXmark}
                                                                />
                                                                <Col className="col-img">
                                                                    {this.renderImgFile("../../../fileIcon/" + extension + ".png", index)}
                                                                    {loadingFile[index] ?
                                                                        loadingFile[index].processing ?
                                                                            <div className="col-carga">
                                                                                <CircularProgressbar
                                                                                    value={0}
                                                                                    text={`Cargando`}
                                                                                    styles={
                                                                                        buildStyles({
                                                                                            textColor: "#6c757d",
                                                                                            pathColor: "#6c757d"
                                                                                        })
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            :
                                                                            loadingFile[index].value === loadingFile[index].total ?
                                                                                <></>
                                                                                :
                                                                                <div className="col-carga">
                                                                                    <CircularProgressbar
                                                                                        value={loadingFile[index].value}
                                                                                        maxValue={loadingFile[index].total}
                                                                                        text={`${Math.round(loadingFile[index].value * 100 / loadingFile[index].total)}%`}
                                                                                        styles={
                                                                                            buildStyles({
                                                                                                textColor: "#6c757d",
                                                                                                pathColor: "#6c757d"
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                        :
                                                                        <></>
                                                                    }
                                                                </Col>
                                                                <Col className="col-name">{name}</Col>
                                                            </Row>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button disabled={this.state.isSaving} variant="secondary" as={Link} to="..">Cancelar</Button>
                                <Button disabled={this.state.isSaving} variant="warning" onClick={this.saveSubmission}>Enviar</Button>
                            </Modal.Footer>
                        </>
                    }

                </Modal>
            </div>
        )
    }
}


export default withParams(SubmitSubmission);