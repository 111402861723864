import React, { useState } from "react"
import { authenticate } from "../../AWS/aws"
import { Row, Col, Button, Form, Container } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import './Login.css'
import { faEye } from "@fortawesome/free-regular-svg-icons";
import Loading from "../../Components/Loading"
import logo from "../../Assets/Img/logo.png"

const Login = () => {

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const [typePassword, setTypePassword] = useState(true)

    const [errorUsernamePassword, setErrorUsernamePassword] = useState(false)

    const [validUsername, setValidUsername] = useState("")
    const [validPassword, setValidPassword] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    const expresiones = {
        username: /^(\d{1,2}(\d{3}){2}-[\dk])$/,
        password: /^.{8,100}$/
    }
    const handleClick = (event) => {
        event.preventDefault()
        setIsLoading(true)
        authenticate(username, password).then(data => {

            setErrorUsernamePassword(false)

            if (data.newPasswordRequired) {
                console.log(data)
            } else {
                window.location = "/"
            }

        }).catch(err => {
            console.error("Failed to login", err)
            setErrorUsernamePassword(true)
            setIsLoading(false)
        })
    }

    const validateUsername = () => {
        onChange(username)
        if (expresiones.username.test(username)) {
            setValidUsername("true")
        } else {
            setValidUsername("false")
        }
    }

    const validatePassword = () => {
        if (expresiones.password.test(password)) {
            setValidPassword("true")
        } else {
            setValidPassword("false")
        }
    }

    const onChange = (value) => {
        // Eliminar cualquier guion previo
        value = value.replace(/-/g, '');
      
        // Verificamos si tiene más de 2 caracteres
        if (value.length > 2) {
          // Colocamos un '-' antes del último carácter
          value = value.slice(0, -1) + '-' + value.slice(-1);
        }
      
        // Convertimos todo a mayúsculas
        value = value.toLowerCase();
      
        // Establecemos el valor modificado
        setUsername(value);
      }

    return (
        <div className="Login">
            <Container className="login-container">

                <Row className="justify-content-center gx-0">
                    <Col></Col>
                    <Col md={8} lg={6} xl={5}>
                        <div className="d-flex justify-content-center"><img className="img-logo" alt="Taller Diseño" src={logo} /></div>
                        <h3 className="login-titulo">Ingresa al Taller</h3>
                        <p>Usuario</p>
                        <div className="grupo-input">
                            <Form.Control className={classNames({ 'login-formulario': true, 'input-check': validUsername === 'true' && !errorUsernamePassword, 'input-xmark': validUsername === 'false' || errorUsernamePassword })} type="text" id="username" onKeyUp={validateUsername} onBlur={validateUsername} placeholder="12345678-9" onChange={(event) => onChange(event.target.value)} value={username} />
                            <FontAwesomeIcon className={classNames({ 'icon-formulario': true, 'check': validUsername === 'true' && !errorUsernamePassword })} icon={faCircleCheck} />
                            <FontAwesomeIcon className={classNames({ 'icon-formulario': true, 'xmark': validUsername === 'false' || errorUsernamePassword })} icon={faCircleXmark} />
                        </div>
                        {validUsername === 'false' &&
                            <div className="message-error">
                                <p>Ingrese su rut sin puntos y con guion</p>
                            </div>
                        }


                        <p>Contraseña</p>
                        <div className="grupo-input">
                            <Form.Control className={classNames({ 'login-formulario': true, 'input-check': validPassword === 'true' && !errorUsernamePassword, 'input-xmark': validPassword === 'false' || errorUsernamePassword })} type={`${typePassword ? "password" : "text"}`} id="password" onKeyUp={validatePassword} onBlur={validatePassword} placeholder="Ejemplo1." onChange={(event) => setPassword(event.target.value)} />
                            <FontAwesomeIcon className={classNames({ 'icon-formulario': true, 'check': validPassword === 'true' && !errorUsernamePassword })} icon={faCircleCheck} />
                            <FontAwesomeIcon className={classNames({ 'icon-formulario': true, 'xmark': validPassword === 'false' || errorUsernamePassword })} icon={faCircleXmark} />
                            <FontAwesomeIcon onClick={() => setTypePassword(!typePassword)} className={classNames({ 'icon-eye-formulario': true, 'accompanied': validPassword === 'false' || errorUsernamePassword || validPassword === 'true' })} icon={faEye} />
                        </div>

                        {validPassword === 'false' &&
                            <div className="message-error">
                                <p>La contraseña debe contener 8 o más caracteres</p>
                            </div>
                        }

                        <div className="login-redirect">
                            <p >¿Problemas con tu contraseña?</p> 
                            <p>Comunicate con tu profesor.</p>
                        </div>

                        <Row className="gx-0">
                            <Col>
                                {errorUsernamePassword &&
                                    <div className="message-error mt-10">
                                        <p>El rut o contraseña es incorrecta <button onClick={() => { setErrorUsernamePassword(false) }} className="delete-error"><FontAwesomeIcon icon={faTrashCan} /></button></p>
                                    </div>
                                }
                            </Col>
                            <Col style={{flex:"0 0 auto", width:"120px"}}>
                                <div className="login-redirect">
                                    {isLoading ?
                                        <Button className="login-button" size="lg" variant="secondary" onClick={handleClick} disabled><Loading disabledAnimation /></Button>
                                        :
                                        <Button className="login-button" size="lg" variant="secondary" onClick={handleClick}>Ingresar</Button>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        </div>
    )
}

export default Login