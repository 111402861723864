import React, { Component } from "react"
import { Row, Col, Form } from "react-bootstrap";
import { useParams, Link, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSave } from "@fortawesome/free-regular-svg-icons";
import { faUsers, faXmark } from '@fortawesome/free-solid-svg-icons'
import Loading from "../../Components/Loading"
import { Api } from "../../AWS/aws"
import dateFormat from "../../Assets/Js/dateFormat"
import Swal from 'sweetalert2'
import "./AdvanceTeacher.css"

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class AdvanceTeacher extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            advancesDescription: []
        };
    }

    getAdvances(id) {
        return Api({
            path: `/advances/${id}`
        })
    }

    postAdvances(body) {
        return Api({
            path: `/advances`,
            method: "POST",
            body: body
        })
    }

    componentDidMount() {
        this.getAdvances(this.props.params.id).then((res) => {
            if (res.code === 0) {
                let arr = res.body.sort(function (a, b) { return a.num - b.num });
                this.setState({ isLoading: false, advancesDescription: arr })
            } else {
                this.setState({ isLoading: false })
            }
        })
    }

    render() {
        return (
            <div className="AdvanceTeacher">
                {this.state.isLoading ?
                    <div className="container-info">
                        <Loading disabledAnimation />
                    </div>
                    :
                    <>
                        {
                            this.state.advancesDescription.map((data, index) => {
                                return (
                                    <div className="container-info" key={index}>
                                        <div style={{ position: "relative" }}>
                                            {data.isEditEndDate ?
                                                <h1 className="title">
                                                    Avance {data.num} /
                                                    <Form.Control disabled={data.isSaving} className="changeName" type="datetime-local" value={data.newEndDate.substr(0, 16)} onChange={(e) => {
                                                        let aux = this.state.advancesDescription
                                                        aux[index].newEndDate = e.target.value + ":59Z"
                                                        this.setState({ advancesDescription: aux })
                                                    }} />


                                                </h1>
                                                :
                                                <h1 className="title">Avance {data.num} / {dateFormat("dateTime", data.endDate)}</h1>
                                            }
                                            <div className="edit">
                                                {data.isEditEndDate ?
                                                    <>
                                                        {data.isSaving ?
                                                            <div style={{ display: "inline-block" }}>
                                                                <Loading disabledAnimation size="sm" />
                                                            </div>
                                                            :
                                                            <FontAwesomeIcon
                                                                icon={faSave}
                                                                onClick={() => {
                                                                    let aux = this.state.advancesDescription
                                                                    if (!aux[index].isSaving) {
                                                                        aux[index].isSaving = true
                                                                        this.setState({ advancesDescription: aux })

                                                                        let body = {
                                                                            endDate: aux[index].newEndDate,
                                                                            id: aux[index].id
                                                                        }
                                                                        this.postAdvances(body).then(res => {
                                                                            Swal.fire({
                                                                                icon: 'success',
                                                                                text: 'Avance actualizado exitosamente!',
                                                                                confirmButtonColor:"#ffc107"
                                                                            })
                                                                            let aux = this.state.advancesDescription
                                                                            aux[index].isEditEndDate = false
                                                                            aux[index].isSaving = false
                                                                            aux[index].endDate = aux[index].newEndDate
                                                                            this.setState({ advancesDescription: aux })
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        }

                                                        <FontAwesomeIcon
                                                            icon={faXmark}
                                                            className="ps-2"
                                                            onClick={() => {
                                                                let aux = this.state.advancesDescription
                                                                if (!aux[index].isSaving) {
                                                                    aux[index].isEditEndDate = false
                                                                    this.setState({ advancesDescription: aux })
                                                                }
                                                            }}
                                                        />
                                                    </>
                                                    :
                                                    <FontAwesomeIcon
                                                        icon={faEdit}
                                                        onClick={() => {
                                                            let aux = this.state.advancesDescription
                                                            let arr = aux.filter(data => {
                                                                return data.isEditEndDate
                                                            })
                                                            if (arr.length === 0) {
                                                                aux[index].isEditEndDate = true
                                                                aux[index].newEndDate = aux[index].endDate
                                                                this.setState({ advancesDescription: aux })
                                                            }
                                                        }}
                                                    />
                                                }
                                            </div>

                                        </div>
                                        <Row className="row-center">
                                            {data.advances.map((advance, index) => {
                                                return (
                                                    <Col key={index} className="col-advance">
                                                        <Link to={advance.id} style={{ color: "rgb(var(--color-7))" }} className={"text-decoration-none"}>
                                                            {
                                                                advance.portada?
                                                                <img style={{height:"150px"}} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + advance.portada} alt="archivo" />
                                                                :
                                                                <></>
                                                            }
                                                            <div className="group-name"><p>{advance.group.name}</p></div>
                                                            <p>{dateFormat("dateTime", advance.date)}</p>
                                                        </Link>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </div>
                                )
                            })
                        }
                    </>
                }
                <Outlet />
            </div>
        )
    }
}

export default withParams(AdvanceTeacher);