import React, { useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';

const TooltipButton = ({ message,bottom='0%',margin=10, color='rgb(var(--color-7))' }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const target = useRef(null);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div style={{ display: 'inline-block' }}>
      {/* Botón de exclamación */}
      <InfoCircleFill
        onClick={toggleTooltip}
        ref={target}
        style={{
          borderRadius: '50%',
          color: color,
          border: 'none',
          cursor: 'pointer',
          fontSize: '15px',
          height:25,
          width:25,
          marginLeft:margin,
          marginRight:margin,
          marginBottom:bottom
        }}
        />

      {/* Tooltip que aparece encima de los elementos */}
      {
        <Overlay target={target.current} show={showTooltip} placement="right">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            {message}
          </Tooltip>
        )}
      </Overlay>
      }
    </div>
  );
};

export default TooltipButton;
