import React, { Component } from "react"
import { Spinner } from "react-bootstrap";

export default class Loading extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="Loading">
                {this.props.size?
                this.props.disabledAnimation ?
                    <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant={this.props.variant ? this.props.variant : "dark"} size={this.props.size}/>
                    </div>
                    : <div className="d-flex justify-content-center animate__animated animate__fadeInDown">
                        <Spinner animation="border" variant={this.props.variant ? this.props.variant : "dark"}  size={this.props.size}/>
                    </div>
                
                :
                this.props.disabledAnimation ?
                    <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant={this.props.variant ? this.props.variant : "dark"}/>
                    </div>
                    : <div className="d-flex justify-content-center animate__animated animate__fadeInDown">
                        <Spinner animation="border" variant={this.props.variant ? this.props.variant : "dark"} />
                    </div>
                
                }
                

            </div>
        )
    }
}